.widget {
  min-width: 320px;
  width: 90vw;
  height: 180px;
  background: linear-gradient(274.42deg, #92a4fd1b 0%, #384b5d3c 124.45%);
  /* blue-shadow */
  /* box-shadow: 0px 10px 22px rgba(149, 173, 254, 0.3); */
  border-radius: 20px;
  position: relative;
  color: white;
  padding: 30px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  

}

@media (min-width: 600px) {
  /* Apply only when screen size is larger than 600px */
  .widget{
    width: 600px; /* Set width to 600px on larger screens */
  }
}

.col-1 button {
  padding: 5px;
  border-radius: 20px;
  width: 100px;
  border: none;
  height: 40px;

  margin-top: 10px;

  /* Purple-Linear */
  background: linear-gradient(274.42deg, #c58bf2 0%, #eea4ce 124.45%);
  border-radius: 50px;

  /* Text / Caption (Semi-bold) */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 15px;
  /* identical to box height, or 150% */

  /* white-color */
  color: #ffffff;
}

.col-1 h3,
.col-1 h5 {
  margin: 0.4rem;
}
