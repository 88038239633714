@tailwind base;
@tailwind components;
@tailwind utilities;



/* For Webkit browsers (Chrome & Safari) */
/* ::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #ffffffc3;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: #bfbfbf3a;
  border-radius: 5px;
} */



html,body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
  color: white;
  width: 100vw;
  height: 100vh;
}


