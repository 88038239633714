.container button {
  position: initial;
  background: linear-gradient(274.42deg, #ffae00 0%, #f12711 124.45%);
  border-radius: 20px;
  color: white;

  width: 80px;
  height: 30px;
  border: none;
  padding: 8px;
  align-self: flex-end;
}

.container {
  width: 90vw;
  max-width: 600px;
  min-width: 320px;
  min-height: 180px;
  max-height: 180px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;

  font-size: 0.9rem;
  padding: 0;

  color: #fff;
  align-items: left;
  overflow: hidden;

  /* Blue-Linear */
  background: linear-gradient(274.42deg, #606a99bd 0%, #314558d1 124.45%);
  border-radius: 16px;

  position: relative;
}

@media (min-width: 600px) {
  /* Apply only when screen size is larger than 600px */
  .container{
    width: 600px; /* Set width to 600px on larger screens */
  }
}

.graph {
  display: flex;
  align-self: flex-end;
  justify-content: center;
  padding: 0;
  margin: 0;
  transform: translateY(50px);
}

.headings {
  display: flex;
  flex-direction: column;
  margin: 20px;
  position: absolute;
}

.heart_rate {
  margin-top: 5px;
  color: #92a3fd;
  font-weight: 600;
}

.tooltip {
  background: linear-gradient(274.42deg, #ffae00 0%, #f12711 124.45%);
  padding: 8px;
  border: none;
}
